import {
	authorizedByPermission,
	DossierDownload, formatter,
	fullWidth,
	SbContractCard,
	SbLogoProps,
	SbOrderCard,
	SbPageHeader,
	SbSwitch, SbTypography,
	TranslationProvider,
	useMobileView,
	userSessionProvider,
} from "@surebase/shared-component-library";
import {permissions} from "../../global/Permissions";
import React, {useEffect, useState} from "react";
import {Settings} from "../../global/Settings";
import {
	Box,
	CircularProgress,
	Stack,
} from "@mui/material";
import {Portal} from "../../services/portal/graphql/Portal";
import {PortalDataRepository} from "../../services/portal/graphql/PortalDataRepository";
import {ContractsDataRepository} from "../../services/contracts/graphql/ContractsDataRepository";
import {Contracts} from "../../services/contracts/graphql/Contracts";
import {useNavigate} from "react-router-dom";
import {ContactCard, ContactCardProps} from "../components/ContactCard";
import {CustomersDataRepository} from "../../services/customers/graphql/CustomersDataRepository";
import {DossierDataRepository} from "../../services/dossiers/graphql/DossierDataRepository";
import {CompaniesDataRepository} from "../../services/companies/CompaniesDataRepository";
import {Companies} from "../../services/companies/Companies";
import {DossierHelper} from "../../helpers/DossierHelper";
import {NotFoundCard, NotFoundCardProps} from "../components/NotFoundCard";
import {ProductsDataRepository} from "../../services/products/ProductsDataRepository";
import {Products} from "../../services/products/Products";
import {TenantHelper} from "../../helpers/TenantHelper";
import {IncentiveHelper} from "../../components/IncentiveHelper";
import {Incentive} from "../../components/SbIncentiveCard";
import {Environment} from "../../global/Environment";

export const ContractsPage = authorizedByPermission(() => {
	const inMobileView = useMobileView();
	const navigate = useNavigate();
	const [loadingContracts, setLoadingContracts] = useState<boolean>(true);
	const [fetchPageData, setFetchPageData] = useState<boolean>(true);
	const [contracts, setContracts] = useState<Contracts.Contract[]>([]);
	const [orders, setOrders] = useState<Portal.Order[]>([]);
	const [expandedContract, setExpandedContract] = useState<string | null>(null);
	const [insurerDetailsRecord, setInsurerDetailsRecord] = useState<Record<string, ContactCardProps>>({});
	const [supplierDetailsRecord, setSupplierDetailsRecord] = useState<Record<string, SbLogoProps>>({});
	const [showAllContracts, setShowAllContracts] = useState<boolean>(false);
	const [coverageTypes, setCoverageTypes] = useState<Record<string, string>>({});
	const [customerIdsPerBroker, setCustomerIdsPerBroker] = useState<string[]>([]);
	const [orderIncentives, setOrderIncentives] = useState<Map<string, Incentive[]>>(new Map<string, Incentive[]>());
	const [incentiveLogoProps, setIncentiveLogoProps] = useState<SbLogoProps>();
	const onSwitchChange = (checked: boolean) => {
		setShowAllContracts(checked);
		setFetchPageData(true);
	};
	
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		setExpandedContract(urlParams.get("id"));
		
		const showAllParameter = urlParams.get("i");
		if ((showAllParameter && showAllParameter === "1"))
			onSwitchChange(true);
		
	}, [navigate]);
	
	useEffect(() => {
		setSupplierDetailsRecord(prevSupplierDetailsRecord => {
			const tempRecord: Record<string, SbLogoProps> = {};
			const keys = Object.keys(prevSupplierDetailsRecord);
			const length = keys.length;
			for (let i = 0; i < length; i++) {
				const key = keys[i];
				const supplier = {...prevSupplierDetailsRecord[key]};
				supplier.imgStyle = {
					width: inMobileView ? "80px" : "123px",
					height: inMobileView ? "40px" : "60px"
				};
				
				tempRecord[key] = supplier;
			}
			
			return tempRecord;
		});
	}, [inMobileView]);
	
	
	if (fetchPageData) {
		setFetchPageData(false);
		if (TenantHelper.isSurebaseTenant()) {
			loadSurebaseData().then(() => {
				return;
			});
			
		} else if (TenantHelper.isOverstappenTenant()) {
			loadOverstappenData().then(() => {
				return;
			});
		}
	}
	
	if (loadingContracts) {
		return <Box sx={{display: "flex", width: "100%", height: "100%"}}>
			<CircularProgress style={{margin: "auto"}}/>
		</Box>;
	}
	
	const getInsurerLogoProps = (contract: Contracts.Contract): SbLogoProps | undefined => {
		const insurerDetails = contract.party.filter(x => x.entityType === "principalInsurer").first();
		if (!insurerDetails || !insurerDetails.refKey)
			return undefined;
		
		const insurer = insurerDetailsRecord[insurerDetails.refKey];
		if (!insurer)
			return undefined;
		
		return {
			src: Environment.assetsUrl.replace("{type}", "companies").replace("{id}", insurerDetails.refKey),
			alt: "Insurer logo",
			imgStyle: {
				width: "123px",
				height: "60px"
			},
			popupSlot: <ContactCard {...insurer} style={{width: "fit-content"}}/>
		};
	};
	
	const getBrokerLogoProps = (contract: Contracts.Contract): SbLogoProps | undefined => {
		if (customerIdsPerBroker.length < 2)
			return undefined;
		
		const brokerDetails = contract.party.filter(x => x.entityType === "intermediary").first();
		if (!brokerDetails)
			return undefined;
		
		const broker = brokerDetails as Contracts.Intermediary;
		if (!broker)
			return undefined;
		
		return {
			src: Environment.assetsUrl.replace("{type}", "brokers").replace("{id}", broker.porIdBroker),
			alt: "Broker logo",
			imgStyle: {
				width: "123px",
				height: "60px"
			},
			popupSlot: <ContactCard heading={"My advisor"} name={"Mock broker"} contactNumber={"06 number"}
				emailAddress={"an email"} address={"address"}/>
		};
	};
	
	async function loadSurebaseData(): Promise<void> {
		CustomersDataRepository.getCustomerIds().then((customerIds: string[]) => {
			setCustomerIdsPerBroker(customerIdsPerBroker);
			return ContractsDataRepository.getContracts(showAllContracts);
		}).then((contractsResponse: Contracts.Contract[]) => {
			setContracts(contractsResponse);
			const insurerIds: string[] = principleInsurersToRetrieve(contractsResponse);
			return CompaniesDataRepository.getPrincipalInsurers(insurerIds);
		}).then((insurerDetails: Companies.Company[]) => {
			const length = insurerDetails.length;
			const tempRecord: Record<string, any> = insurerDetailsRecord ?? {};
			for (let i = 0; i < length; i++) {
				const insurer = insurerDetails[i];
				tempRecord[insurer.id] = {
					heading: "My insurer",
					name: insurer.surname,
					address: formatter.address({
						city: insurer.city,
						street: insurer.street,
						postalCode: insurer.postalCode,
						houseNumber: insurer.houseNumber.toString(),
						houseNumberAddition: insurer.houseNumberAddition
					}),
					contactNumber: formatter.contactNumber(insurer.telephoneNumber)
				};
			}
			
			setInsurerDetailsRecord(tempRecord);
			return ContractsDataRepository.getContractsCoverageTypes();
		}).then((coverageTypesResult: Contracts.CoverageTypeItem[]) => {
			const record: Record<string, string> = Object.fromEntries(
				coverageTypesResult.map(x => [x.code, x.description])
			);
			
			setCoverageTypes(record);
			setLoadingContracts(false);
		});
	}
	
	async function loadOverstappenData(): Promise<void> {
		if (!userSessionProvider.userSession?.user)
			throw new Error("No user session");
		
		const firstNames = userSessionProvider.userSession?.user.givenName;
		const lastNames = userSessionProvider.userSession?.user.familyName;
		PortalDataRepository.getCustomerRegistry(firstNames, lastNames).then((customerRegistry: Portal.CustomerRegisterDetailResult) => {
			if (!customerRegistry || customerRegistry.customerRegistryId === "")
				throw new Error("Customer not found");
			
			return PortalDataRepository.getOrders(customerRegistry.customerRegistryId);
		}).then((customerOrders: Portal.Order[]) => {
			const ordersArray: Portal.Order[] = [];
			const ordersLength = customerOrders.length;
			const krnsForSuppliers: string[] = [];
			const orderIncentiveLookup = new Map<string, Incentive[]>();
			for (let i = 0; i < ordersLength; i++) {
				const order = customerOrders[i];
				if (order.productCategory !== "HEALTH_CARE")
					continue;
				
				if (!order.package || !order.package.policyDetails || order.package?.policyDetails?.length === 0)
					continue;
				
				ordersArray.push(order);
				const dentalIncentive = IncentiveHelper.getDentalIncentive(order);
				if (dentalIncentive) {
					orderIncentiveLookup.set(order.id, [dentalIncentive]);
				}
				
				if (order.package.customProperties?.supplierKrn && !krnsForSuppliers.includes(order.package.customProperties?.supplierKrn))
					krnsForSuppliers.push(order.package.customProperties?.supplierKrn);
			}
			
			setOrderIncentives(orderIncentiveLookup);
			ProductsDataRepository.getSuppliers(krnsForSuppliers).then((supplierResults: Products.ProductSupplier[]) => {
				const length = supplierResults.length;
				const tempRecord: Record<string, SbLogoProps> = {};
				for (let i = 0; i < length; i++) {
					const supplier = supplierResults[i];
					tempRecord[supplier.krn] = {
						src: supplier.nameLogoUrl ?? "",
						alt: supplier.name,
						imgStyle: {
							width: inMobileView ? "80px" : "120px",
							height: inMobileView ? "40px" : "60px"
						},
						popupSlot: <ContactCard heading={"My insurer"} name={supplier.name} contactNumber={""}
							emailAddress={""} address={""}/>
					};
				}
				
				setSupplierDetailsRecord(tempRecord);
			});
			
			PortalDataRepository.getOverstappenDetails().then((result: Portal.BrokerPayload | null) => {
				setOrders(ordersArray);
				setLoadingContracts(false);
				if (!result)
					return;
				
				setIncentiveLogoProps({
					src: Environment.assetsUrl.replace("{type}", "brokers").replace("{id}", "00435").replace("logo.png", "logo-mark.png"),
					alt: "Overstappen logo mark",
					imgStyle: {
						width: inMobileView ? "80px" : "120px",
						height: inMobileView ? "40px" : "60px"
					},
					popupSlot: <ContactCard
						heading={"My insurer"}
						name={result.name}
						contactNumber={formatter.contactNumber(result.contactNumber)}
						emailAddress={""}
						address={formatter.address({
							city: result.city,
							street: result.street,
							postalCode: result.postalCode,
							houseNumber: result.houseNumber,
							houseNumberAddition: result.houseNumberAddition
						})}/>
				});
			});
		});
	}
	
	function principleInsurersToRetrieve(contracts: Contracts.Contract[]) {
		const length = contracts.length;
		const retrievedInsurerIds = Object.keys(insurerDetailsRecord);
		const insurerIds: string[] = [];
		for (let i = 0; i < length; i++) {
			const contractItem = contracts[i];
			const insurerDetails = contractItem.party.filter(x => x.entityType === "principalInsurer").first();
			if (insurerDetails && insurerDetails.refKey && !retrievedInsurerIds.includes(insurerDetails.refKey) && !insurerIds.includes(insurerDetails.refKey))
				insurerIds.push(insurerDetails.refKey);
		}
		
		return insurerIds;
	}
	
	const getDocumentsForContract = async (contractId: string): Promise<DossierDownload[]> => {
		const documents = await DossierDataRepository.getContractDocuments(contractId);
		return DossierHelper.buildDossierDownloads(documents);
	};
	
	const getDocumentsForOrders = (order: Portal.Order): Portal.Document[] => {
		if (!order.package)
			return [];
		
		const length = order.package.documents.length;
		const documents: Portal.Document[] = [];
		const uniqueDocumentNames: string[] = [];
		for (let i = 0; i < length; i++) {
			const doc = order.package.documents[i];
			if (!DossierHelper.isValidOrderDocument(doc, uniqueDocumentNames))
				continue;
			
			documents.push(doc);
		}
		
		return documents;
	};
	
	const downloadDocumentForOrders = (document: Portal.Document) => {
		if (!document.documentUrl)
			return;
		
		window.open(document.documentUrl, "_blank");
		URL.revokeObjectURL(document.documentUrl);
	};
	
	const onExpandChange = (contractId: string, expanded: boolean) => {
		if (expandedContract !== null && expandedContract === contractId) {
			const urlParams = new URLSearchParams(window.location.search);
			setExpandedContract(urlParams.get("contract"));
			
			//replace query parameter to prevent unwanted expansions
			const url = new URL(window.location.toString());
			url.searchParams.delete("contract");
			window.history.replaceState({}, document.title, url.pathname);
		}
	};
	
	const onOrderExpandChange = (orderId: string, packageOfferNumber: string | null | undefined, expanded: boolean) => {
		if (expandedContract !== null && expandedContract === orderId) {
			const urlParams = new URLSearchParams(window.location.search);
			setExpandedContract(urlParams.get("contract"));
			
			//replace query parameter to prevent unwanted expansions
			const url = new URL(window.location.toString());
			url.searchParams.delete("contract");
			window.history.replaceState({}, document.title, url.pathname);
		}
	};
	
	const getContractCoverage = (productDescription: string): string => {
		const coverageDescription = coverageTypes[productDescription];
		if (!coverageDescription)
			return productDescription;
		
		return coverageDescription;
	};
	
	const getSwitcher = () => {
		if (TenantHelper.isOverstappenTenant() || contracts.length === 0)
			return undefined;
		
		return <Stack direction="row" gap={inMobileView ? "4px" : 1} alignItems="center"
			style={{marginLeft: "auto"}}>
			<SbTypography variant={"shortcutAction"} color={theme => theme.palette.text.secondary}>
				Show all
			</SbTypography>
			<SbSwitch SwitchProps={{
				checked: showAllContracts,
				onChange: (event, checked) => onSwitchChange(checked)
			}}/>
		</Stack>;
	};
	
	
	const getNotFoundText = (): NotFoundCardProps => {
		return TenantHelper.isOverstappenTenant() ? {
			heading: "No health insurance found",
			subText: "When you purchase health insurance it will be displayed here."
		} : {
			heading: "No products found",
			subText: "When you purchase a product it will be displayed here."
		};
	};
	
	return <TranslationProvider name={Settings.languageModule}>
		<Stack gap={2} style={{...fullWidth}}>
			<Stack>
				<SbPageHeader pageName={"My products"} overviewRoute={"/customer-portal"} disableBreadcrumbs={true}
					childComponent={getSwitcher()}/>
			</Stack>
			
			<Stack gap={4} direction={inMobileView ? "column" : "row"} style={{...fullWidth}}>
				<Stack gap={3} style={{width: "100%", height: "100%"}}>
					{loadingContracts && (
						<Box sx={{display: "flex", width: "100%", height: "100%"}}>
							<CircularProgress style={{margin: "auto"}}/>
						</Box>
					)}
					
					{(!loadingContracts && contracts.length === 0 && orders?.length === 0) && (
						<NotFoundCard {...getNotFoundText()}/>
					)}
					
					{!loadingContracts && contracts?.map((contract, index) => (
						<SbContractCard key={index}
							variant={"detailedAccordion"}
							contract={contract}
							insurerLogoProps={getInsurerLogoProps(contract)}
							brokerLogoProps={getBrokerLogoProps(contract)}
							// contractActions={contractActions}
							getDocumentsFunction={() => getDocumentsForContract(contract.id)}
							getCoverageFunction={getContractCoverage}
							defaultExpanded={(expandedContract !== null && expandedContract === contract.id)}
							scrollTo={(expandedContract !== null && expandedContract === contract.id)}
							onExpandChange={onExpandChange}
							inMobileView={inMobileView}
						/>
					))}
					{!loadingContracts && orders?.map((order, index) => (
						<React.Fragment key={index}>
							<SbOrderCard key={index}
								variant={"detailedAccordion"}
								order={order}
								supplierLogoProps={supplierDetailsRecord[order.package?.customProperties?.supplierKrn ?? ""]}
								defaultExpanded={(expandedContract !== null && expandedContract === order.id)}
								scrollTo={(expandedContract !== null && expandedContract === order.id)}
								onExpandChange={onOrderExpandChange}
								inMobileView={inMobileView}
								getValidDocuments={getDocumentsForOrders}
								onDownloadDocument={downloadDocumentForOrders}
								style={{
									minHeight: "94px",
								}}
							/>
							{IncentiveHelper.getIncentiveCards(order, orderIncentives, "detailedAccordion", expandedContract, inMobileView, incentiveLogoProps)}
						</React.Fragment>
					))}
				</Stack>
			</Stack>
		</Stack>
	</TranslationProvider>;
}, [permissions.contractsRead]);
