import {Incentive, SbIncentiveCard} from "./SbIncentiveCard";
import {Portal} from "../services/portal/graphql/Portal";
import React, {ReactNode} from "react";
import {DentistryToothBoldIcon, DossierDownload, SbLogoProps, useTranslation} from "@surebase/shared-component-library";
import {Environment} from "../global/Environment";

export class IncentiveHelper {
	
	static getDentalIncentive = (order: Portal.Order): Incentive | undefined => {
		if (!Environment.incentivesEnabled)
			return undefined;
		
		if (!order.userDesiresIncentive || !order.package)
			return undefined;
		
		const dentalIncentivePolicy = order.package.policyDetails?.find(x => x.productDescription === "Overstappen.nl Tandongevallenverzekering");
		if (dentalIncentivePolicy === undefined || dentalIncentivePolicy.underwritingResult !== "V1")
			return undefined;
		
		return {
			id: dentalIncentivePolicy?.refKey ?? order.id + "tandongevallenverzekering",
			orderId: order.id,
			contractNumber: dentalIncentivePolicy.contractNumber ?? "",
			name: "Tandongevallenverzekering",
			effectiveDate: "2025-01-01",
			expiryDate: "2025-12-31",
			coverage: "Tandartskosten na een ongeval",
			paymentTermInMonths: 12,
			totalInstallmentAmount: 3,
			maximumCoverAmount: 25000
		};
	};
	
	static paymentTermDescription = (paymentTermInMonths: number, mobileView?: boolean) => {
		if (paymentTermInMonths === null || paymentTermInMonths === 0)
			return "";
		
		const translation = useTranslation();
		if (paymentTermInMonths === 0) {
			return mobileView ? translation("free") : `/ ${translation("free")}`;
		} else if (paymentTermInMonths === 1) {
			return mobileView ? translation("p.m") : `/ ${translation("month")}`;
		} else if (paymentTermInMonths === 3) {
			return mobileView ? translation("p.q") : `/ ${translation("quarter")}`;
		} else if (paymentTermInMonths === 6) {
			return mobileView ? translation("p.h.y") : `/ ${translation("half year")}`;
		} else if (paymentTermInMonths === 12) {
			return mobileView ? translation("p.y") : `/ ${translation("year")}`;
		} else {
			return `/ ${paymentTermInMonths} ` + translation("months");
		}
	};
	
	static getIncentiveCards(
		order: Portal.Order,
		orderIncentivesMap: Map<string, Incentive[]>,
		variant: "summary" | "detailed" | "detailedAccordion",
		expandedIncentiveId: string | null,
		inMobileView: boolean,
		logoProps?: SbLogoProps,
		onClick?: (incentiveId: string, variant: "summary" | "detailed" | "detailedAccordion") => void): ReactNode | undefined {
		if (!Environment.incentivesEnabled || !order.userDesiresIncentive)
			return undefined;
		
		const orderIncentives = orderIncentivesMap.get(order.id);
		if (!orderIncentives || orderIncentives.length === 0)
			return undefined;
		
		return <>
			{orderIncentives.map((incentive, index) => (
				<SbIncentiveCard key={index} variant={variant} icon={<DentistryToothBoldIcon width={32} height={32}/>}
					incentive={incentive}
					inMobileView={inMobileView}
					supplierLogoProps={logoProps}
					defaultExpanded={(expandedIncentiveId !== null && expandedIncentiveId === incentive.id)}
					onClick={() => onClick ? onClick(incentive.id, variant) : undefined}
					getDocumentsFunction={(incentiveId) => {
						const result: DossierDownload[] = [
							{
								dossierId: "terms",
								description: "Terms and conditions",
								onDownload(parentId: string, dossierId: string) {
									const url = "https://voorwaarden.risk.nl/Polisvoorwaarden_Tandongevallenverzekering_%28OS-TOV-2024.11%29-4-8318.pdf";
									window.open(url, "_blank");
									URL.revokeObjectURL(url);
								}
							},
							{
								dossierId: "insuranceCard",
								description: "Insurance card",
								onDownload(parentId: string, dossierId: string) {
									const url = "https://verzekeringskaarten.nl/risk-assuradeuren/tandongevallenverzekering";
									window.open(url, "_blank");
									URL.revokeObjectURL(url);
								}
							}
						];
						
						return Promise.resolve(result);
					}}/>
			))}
		</>;
	}
}
