import {LocalDate} from "@js-joda/core";
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	CircularProgress,
	Divider, IconButtonProps,
	Stack,
	useTheme
} from "@mui/material";
import {
	ChevronDownIcon,
	ChevronRightIcon, ChevronUpIcon, DossierDownload, Download02Icon,
	formatter,
	Portal,
	SbIconButton, SbLogo,
	SbLogoProps,
	SbTypography,
	SbTypographyRaw, useMobileView
} from "@surebase/shared-component-library";
import React, {CSSProperties, ReactNode, useRef, useState, useEffect} from "react";
import {grey} from "@mui/material/colors";
import {IncentiveHelper} from "./IncentiveHelper";

export interface Incentive {
	id: string;
	orderId: string;
	contractNumber: string;
	name: string;
	effectiveDate: string;
	expiryDate: string;
	coverage: string;
	paymentTermInMonths: number;
	totalInstallmentAmount: number;
	maximumCoverAmount: number;
}

export interface SbIncentiveCardProps {
	readonly variant: "summary" | "detailed" | "detailedAccordion";
	readonly incentive: Incentive;
	readonly icon: ReactNode;
	readonly supplierLogoProps?: SbLogoProps;
	readonly style?: CSSProperties;
	readonly defaultExpanded?: boolean;
	readonly scrollTo?: boolean;
	readonly inMobileView?: boolean;
	
	onClick?(): void;
	
	onExpandChange?(orderId: string, policyId: string | null | undefined, expanded: boolean): void;
	
	getDocumentsFunction?(incentiveId: string): Promise<DossierDownload[]>;
	
	onDownloadDocument?(document: Portal.Document): void;
}

export function SbIncentiveCard(props: SbIncentiveCardProps) {
	const theme = useTheme();
	const itemRef = useRef<null | HTMLDivElement>(null);
	const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);
	const [documents, setDocuments] = useState<DossierDownload[]>();
	const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);
	const [focusAccordion, setFocusAccordion] = useState<boolean>(false);
	const focusedCss: CSSProperties = {
		borderRadius: "8px",
		border: "1px solid #BDBDBD",
		background: "#FFF",
		boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.12)"
	};
	
	useEffect(() => {
		if (props.scrollTo)
			itemRef.current?.scrollIntoView();
	}, [props.scrollTo]);
	
	useEffect(() => {
		if (props.defaultExpanded) {
			setAccordionExpanded(true);
			setFocusAccordion(true);
			if (!documents && !documentsLoading && props.getDocumentsFunction) {
				setDocumentsLoading(true);
				props.getDocumentsFunction(props.incentive.id).then((documentArray: DossierDownload[]) => {
					setTimeout(() => setDocuments(documentArray), 0);
					setDocumentsLoading(false);
				});
			}
			
			if (props.onExpandChange)
				props.onExpandChange(props.incentive.id, props.incentive.orderId, true);
		}
	}, [documents, documentsLoading, props, props.defaultExpanded]);
	
	const onToggleAccordion = () => {
		const newValue = !accordionExpanded;
		setAccordionExpanded(newValue);
		setFocusAccordion(false);
		
		if (newValue && !documents && !documentsLoading && props.getDocumentsFunction) {
			setDocumentsLoading(true);
			props.getDocumentsFunction(props.incentive.id).then((documentArray: DossierDownload[]) => {
				setTimeout(() => setDocuments(documentArray), 0);
				setDocumentsLoading(false);
			});
		}
		
		if (props.onExpandChange)
			props.onExpandChange(props.incentive.id, props.incentive.orderId, true);
	};
	
	const handleOnProductClick = () => {
		if (!props.onClick)
			return;
		
		props.onClick();
	};
	
	const baseStackStyle: CSSProperties = {width: "100%", flex: "1 1", overflow: "hidden", minWidth: 0};
	if (props.variant === "summary") {
		return <Card variant="outlined" style={{
			display: "flex",
			alignItems: "center",
			flex: "1 1",
			height: "100%",
			minHeight: "96px",
			width: "100%",
			...props.style,
		}}>
			<Button style={{width: "100%", height: "100%", padding: "16px"}} onClick={handleOnProductClick}>
				<IncentiveSummary icon={props.icon} variant={props.variant} incentiveName={props.incentive.name}/>
			</Button>
		</Card>;
	}
	
	return <Stack style={{...baseStackStyle}}>
		<Accordion variant={"outlined"} expanded={accordionExpanded} onChange={onToggleAccordion} disableGutters={true}
			ref={itemRef} sx={{...(focusAccordion && focusedCss)}}>
			<AccordionSummary sx={{
				padding: "0px",
				".MuiAccordionSummary-content": {
					padding: "0px", width: "100%", flex: "1 1", overflow: "hidden", minWidth: 0
				}
			}}>
				<Stack direction={"row"} alignItems={props.inMobileView ? "flex-start" : "center"} gap={3}
					style={{padding: props.inMobileView ? "16px" : "16px 24px", ...baseStackStyle}}>
					<IncentiveSummary icon={props.icon} variant={"detailed"} incentiveName={props.incentive.name}/>
					<Stack gap={1} alignItems={"flex-end"}>
						<Stack gap={1} direction={props.inMobileView ? "column" : "row"}>
							{props.supplierLogoProps && (
								<SbLogo {...props.supplierLogoProps}/>
							)}
						</Stack>
						
						<Stack gap={"4px"} direction={"row"} alignItems={"center"} style={{...baseStackStyle}}>
							<SbTypographyRaw variant={"header3"}>
								{formatter.currency(props.incentive.totalInstallmentAmount)}
							</SbTypographyRaw>
							<SbTypographyRaw variant={"cardButtonHeader3"} style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								flexShrink: 1,
								minWidth: 0
							}}>
								{IncentiveHelper.paymentTermDescription(props.incentive.paymentTermInMonths, props.inMobileView)}
							</SbTypographyRaw>
						</Stack>
					</Stack>
				</Stack>
			</AccordionSummary>
			{accordionExpanded && <Divider color={grey[300]}/>}
			<AccordionDetails style={{padding: "0", background: "#0DA06F0A"}}>
				<Stack gap={2} direction={props.inMobileView ? "column" : "row"} style={{padding: "24px"}}>
					<IncentiveDetails incentive={props.incentive}/>
					{documentsLoading && (
						<Stack gap={1} alignItems={"flex-start"} style={{flex: "1 0 0"}}>
							<Box sx={{display: "flex", width: "100%", height: "100%"}}>
								<CircularProgress style={{margin: "auto"}}/>
							</Box>
						</Stack>
					)}
					{(!documentsLoading && documents) && (
						<IncentiveDocumentDownloads incentiveId={props.incentive.id} documents={documents}/>
					)}
				</Stack>
			
			</AccordionDetails>
			{accordionExpanded && (
				<Divider color={grey[300]}/>
			)}
			<AccordionActions>
				<Box onClick={onToggleAccordion} style={{width: "100%"}}>
					<Stack>
						<SbIconButton children={
							<Stack direction={"row"} gap={1}>
								<SbTypography variant={"productButton2Bold"} color={theme.palette.text.primary}>
									Close details
								</SbTypography>
								<ChevronUpIcon color={theme.palette.text.primary}
									style={{width: "18px", height: "18px", flexShrink: 0}}/>
							</Stack>
						} IconButtonProps={{
							style: {marginLeft: "auto"},
							size: "small",
							disableRipple: true,
							onClick: onToggleAccordion
						}}/>
					</Stack>
				</Box>
			</AccordionActions>
		</Accordion>
		
		{!accordionExpanded && (
			<Card variant={"outlined"}
				style={{borderTop: "none", padding: "4px 0px 4px 0px", borderRadius: "0px 0px 8px 8px"}}
				onClick={onToggleAccordion}>
				<Stack>
					<SbIconButton children={
						<Stack direction={"row"} gap={1}>
							<SbTypography variant={"productButton2Bold"} color={theme.palette.text.primary}>
								Show details
							</SbTypography>
							<ChevronDownIcon color={theme.palette.text.primary}
								style={{width: "18px", height: "18px", flexShrink: 0}}/>
						</Stack>
					} IconButtonProps={{
						style: {marginLeft: "auto"},
						size: "small",
						disableRipple: true,
						onClick: () => onToggleAccordion
					}}/>
				</Stack>
			</Card>
		)}
	</Stack>;
}

export interface IncentiveSummaryProps {
	readonly incentiveName: string;
	readonly icon: ReactNode;
	readonly variant: "summary" | "detailed";
}

function IncentiveSummary(props: IncentiveSummaryProps) {
	const theme = useTheme();
	const inMobileView = useMobileView();
	
	if (inMobileView && props.variant === "summary") {
		return <Stack direction={"row"} alignItems={"center"} gap={2}
			style={{width: "100%", flex: "1 1", overflow: "hidden", minWidth: 0}}>
			<Stack justifyContent={"center"} style={{color: theme.palette.text.primary}}>
				{props.icon}
			</Stack>
			<Stack gap={2} flex={1} direction={"row"}
				style={{width: "100%", flex: "1 1", overflow: "hidden", minWidth: 0}}>
				<Stack gap={1} style={{width: "100%", flex: "1 1", overflow: "hidden", minWidth: 0}}>
					<SbTypographyRaw variant={"cardButtonHeader3"} color={theme.palette.text.primary} style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						flexShrink: 1,
						minWidth: 0
					}}>
						{props.incentiveName}
					</SbTypographyRaw>
				</Stack>
				
				<Stack alignItems={"flex-end"} justifyContent={"center"} style={{marginLeft: "auto"}}>
					<ChevronRightIcon color={theme.palette.text.primary}/>
				</Stack>
			</Stack>
		</Stack>;
	}
	
	if (inMobileView) {
		return <Stack alignItems={"flex-start"} gap={1}
			style={{width: "100%", flex: "1 1", overflow: "hidden", minWidth: 0}}>
			<Stack direction={"row"} gap={"12px"} style={{width: "100%"}}>
				<Stack style={{color: theme.palette.text.primary}}>
					{props.icon}
				</Stack>
			</Stack>
			<Stack gap={"4px"} style={{width: "100%", flex: "1 1", overflow: "hidden", minWidth: 0}}>
				<Stack direction={"row"} gap={1} style={{width: "100%", flex: "1 1", overflow: "hidden", minWidth: 0}}>
					<SbTypographyRaw variant={"cardButtonHeader3"} color={theme.palette.text.primary} style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						flexShrink: 1,
						minWidth: 0
					}}>
						{props.incentiveName}
					</SbTypographyRaw>
				</Stack>
			</Stack>
		</Stack>;
	}
	
	return <Stack direction={"row"} alignItems={"center"} flexGrow={1} gap={inMobileView ? 1 : 2}
		style={{width: "100%", height: "100%", flex: "1 1", overflow: "hidden", minWidth: 0}}>
		<Stack direction={"row"} gap={2} style={{color: theme.palette.text.primary}}>
			{props.icon}
		</Stack>
		<Stack gap={"4px"} style={{flexGrow: 1, minWidth: 0}}>
			<Stack direction={"row"} gap={1}>
				<SbTypographyRaw variant={"cardButtonHeader3"} color={theme.palette.text.primary}>
					{props.incentiveName}
				</SbTypographyRaw>
			</Stack>
		</Stack>
		{(props.variant === "summary") && (
			<ChevronRightIcon color={theme.palette.text.primary}/>
		)}
	</Stack>;
}

interface IncentiveDetailsProps {
	readonly incentive: Incentive;
}

function IncentiveDetails(props: IncentiveDetailsProps) {
	const theme = useTheme();
	const formatOptions: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	};
	
	const getIncentivePeriod = () => {
		const effectiveDate = formatter.date(LocalDate.parse(props.incentive.effectiveDate), formatOptions);
		const expiryDate = formatter.date(LocalDate.parse(props.incentive.expiryDate), formatOptions);
		return `${effectiveDate} - ${expiryDate}`;
	};
	
	
	return <Stack gap={1} alignItems={"flex-start"} style={{flex: "1 0 0"}}>
		<Stack gap={1} alignItems={"flex-start"} style={{width: "197px"}}>
			<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
				Order number
			</SbTypography>
			<SbTypographyRaw variant={"cardButtonHeader3"} style={{whiteSpace: "pre"}}>
				{props.incentive.contractNumber}
			</SbTypographyRaw>
		</Stack>
		
		<Stack gap={1} alignItems={"flex-start"} style={{width: "197px"}}>
			<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
				Insurance period
			</SbTypography>
			<SbTypographyRaw variant={"cardButtonHeader3"} style={{whiteSpace: "pre"}}>
				{getIncentivePeriod()}
			</SbTypographyRaw>
		</Stack>
		
		<Stack gap={1} alignItems={"flex-start"} style={{width: "197px"}}>
			<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
				Coverage
			</SbTypography>
			<SbTypographyRaw variant={"cardButtonHeader3"}>
				{props.incentive.coverage}
			</SbTypographyRaw>
		</Stack>
		
		<Stack gap={1} alignItems={"flex-start"} style={{width: "197px"}}>
			<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
				Maximum cover
			</SbTypography>
			<SbTypography variant={"cardButtonHeader3"} style={{whiteSpace: "pre"}}
				variables={{coverAmount: formatter.currency(props.incentive.maximumCoverAmount)}}>
				%coverAmount% per incident
			</SbTypography>
		</Stack>
	</Stack>;
}

interface IncentiveDocumentDownloadsProps {
	readonly incentiveId: string;
	readonly documents: DossierDownload[];
}

function IncentiveDocumentDownloads(props: IncentiveDocumentDownloadsProps) {
	const theme = useTheme();
	const iconButtonProps: Partial<IconButtonProps> = {
		color: "primary",
		style: {borderRadius: "0", gap: "8px", marginRight: "auto", padding: "4px 5px 4px 0px"}
	};
	
	return <Stack gap={1} alignItems={"flex-start"} style={{flex: "1 0 0"}}>
		<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
			Download documents
		</SbTypography>
		<Stack gap={"4px"} alignItems={"flex-start"}>
			{(props.documents && props.documents.length === 0) && (
				<SbTypography variant={"buttonSmall"} color={theme.palette.text.primary}>
					No documents found
				</SbTypography>
			)}
			
			{props.documents?.map((document, index) => (
				<SbIconButton
					key={index}
					IconButtonProps={{
						...iconButtonProps,
						onClick: () => document.onDownload(props.incentiveId, document.dossierId)
					}}>
					<Download02Icon width={18} height={18} color={theme.palette.text.primary}/>
					<SbTypography variant={"buttonSmall"} color={theme.palette.text.primary}>
						{document.description}
					</SbTypography>
				</SbIconButton>
			))}
		</Stack>
	</Stack>;
}
