import {
	authorizedByPermission,
	fullWidth,
	Home03Icon,
	parseLocalDate,
	Pencil01Icon,
	PlusCircleIcon,
	SbIconButton,
	SbPageHeader,
	SbParty,
	SbPolicyHolderCard,
	SbPolicyHolderCardProps,
	SbTypography,
	TranslationProvider,
	useMobileView,
	formatter,
	fullSize,
	SbActionsCard,
	SbPartyAccordion,
	generalError, CheckCircleIcon,
} from "@surebase/shared-component-library";
import {permissions} from "../../global/Permissions";
import React, {useState} from "react";
import {Settings} from "../../global/Settings";
import {Customers} from "../../services/customers/graphql/Customers";
import {CustomersDataRepository} from "../../services/customers/graphql/CustomersDataRepository";
import {
	Alert,
	Box,
	CircularProgress,
	Stack
} from "@mui/material";
import {grey} from "@mui/material/colors";
import {CustomerHelper} from "../../helpers/CustomerHelper";
import {LocalDate} from "@js-joda/core";
import {ErrorBoundary} from "react-error-boundary";
import {Contracts} from "../../services/contracts/graphql/Contracts";
import {ContractsDataRepository} from "../../services/contracts/graphql/ContractsDataRepository";
import {CustomerReportMoveDialog} from "./CustomerReportMoveDialog";
import {CustomerReportChangeDialog} from "./CustomerReportChangeDialog";

interface ChildWithBirthDate extends SbParty {
	birthDate?: LocalDate;
}

export const CustomerDetailsPage = authorizedByPermission(() => {
	const [error, setError] = useState<string>();
	const [loadPage, setLoadPage] = useState<boolean>(true);
	const [loadingCustomer, setLoadingCustomer] = useState<boolean>(true);
	const [customer, setCustomer] = useState<Customers.Customer>();
	const [contracts, setContracts] = useState<Contracts.Contract[]>();
	const [subRelations, setSubRelations] = useState<SbParty[]>([]);
	const [openReportMoveDialog, setOpenReportMoveDialog] = React.useState(false);
	const [openReportChangeDialog, setOpenReportChangeDialog] = React.useState(false);
	const [showFormSentAlert, setShowFormSentAlert] = useState(false);
	
	const inMobileView = useMobileView();
	const ordinalMap: { [key: number]: string } = {
		1: "First",
		2: "Second",
		3: "Third",
		4: "Fourth",
		5: "Fifth",
		6: "Sixth",
		7: "Seventh",
		8: "Eighth",
		9: "Ninth",
		10: "Tenth"
	};
	
	if (loadPage) {
		setLoadPage(false);
		CustomersDataRepository.getCustomer().then((customerResult) => {
			if (!customerResult) {
				setError("Customer not found");
				return;
			}
			
			setCustomer(customerResult);
			if (!customerResult?.party)
				return;
			
			const subRelationsArray: SbParty[] = [];
			const childrenArray: ChildWithBirthDate[] = [];
			const length = customerResult.party.length;
			for (let i = 0; i < length; i++) {
				const party = customerResult.party[i];
				if (party.entityType === "partner") {
					const partner = party as Customers.Partner;
					subRelationsArray.push({
						id: partner.refKey ?? "",
						fields: CustomerHelper.buildPartnerFields(partner),
						infoHeader: "Partner"
					});
				} else if (party.entityType === "child") {
					const child = party as Customers.Child;
					childrenArray.push({
						birthDate: parseLocalDate(child.birthDate),
						id: child.refKey ?? "",
						fields: CustomerHelper.buildChildFields(child, customerResult.surname ?? undefined, customerResult.prefixes ?? undefined)
					});
				}
			}
			
			childrenArray.sort((childA, childB) => {
				if (childA.birthDate && childB.birthDate) {
					if (childA.birthDate.equals(childB.birthDate))
						return 0;
					else if (childA.birthDate.isAfter(childB.birthDate))
						return 1;
					else
						return -1;
				}
				
				if (childA.birthDate && !childB.birthDate)
					return 1;
				
				if (!childA.birthDate && childB.birthDate)
					return -1;
				
				return 0;
			});
			
			childrenArray.forEach((child, index) => {
				child.infoHeader = ordinalMap[index + 1] + " child";
			});
			
			subRelationsArray.push(...childrenArray);
			setSubRelations(subRelationsArray);
		}).catch(() => {
			setError("Unable to load customer data");
		}).finally(() => {
			setLoadingCustomer(false);
		});
		
		ContractsDataRepository.getContracts(false).then((contractsResult) => {
			if (!contractsResult)
				return;
			
			setContracts(contractsResult);
		});
	}
	
	if (loadingCustomer) {
		return <Box sx={{display: "flex", width: "100%", height: "100%"}}>
			<CircularProgress style={{margin: "auto"}}/>
		</Box>;
	}
	
	if (error)
		throw new Error(error);
	
	const formatIban = (iban: string | null | undefined): string | undefined => {
		if (!iban)
			return undefined;
		
		return iban.replace(/(\w{2})(\d{2})(\w{4})(\d{10,})/, "$1 $2 $3 $4");
	};
	
	const getInfoProps = (): SbPolicyHolderCardProps => {
		return {
			variant: "detailed",
			policyHolder: {
				id: customer?.id ?? "",
				fullName: CustomerHelper.getCustomerName(customer),
				birthDate: formatter.date(parseLocalDate(customer?.birthDate), CustomerHelper.shortDateFormat),
				street: customer?.street ?? undefined,
				houseNumber: customer?.houseNumber ?? undefined,
				houseNumberAddition: customer?.houseNumberAddition ?? undefined,
				postalCode: customer?.postalCode ?? undefined,
				city: customer?.city ?? undefined,
				email: customer?.email ?? undefined,
				contactNumber: formatter.contactNumber(customer?.mobile ?? customer?.telephoneNumber),
				mainIban: formatIban(customer?.collectionAccountIban)
			},
			actionsSlot: <SbIconButton IconButtonProps={{onClick: () => console.log("go to edit customer")}}>
				<Pencil01Icon width={20} height={20} color={grey[700]}/>
			</SbIconButton>
		};
	};
	
	return <TranslationProvider name={Settings.languageModule}>
		<Stack gap={2} style={{...fullSize}}>
			{showFormSentAlert &&
				<Alert
					severity="success"
					onClose={() => setShowFormSentAlert(!showFormSentAlert)}
					icon={<CheckCircleIcon style={{
						fontWeight: "400",
						fontSize: "14px",
						lineHeight: "20.02px",
						letterSpacing: "0.17px",
						alignSelf: inMobileView ? "center" : "normal"
					}}/>}
				>
					The form has been successfully sent to your advisor.
				</Alert>
			}
			<Stack gap={2}>
				<SbPageHeader pageName={"My details"} overviewRoute={"/customer-portal"} disableBreadcrumbs={true}/>
			</Stack>
			<ErrorBoundary FallbackComponent={generalError}>
				{inMobileView && (
					<Stack gap={2} style={{...fullWidth}}>
						<SbActionsCard containerStyle={{backgroundColor: "#FAFAFA", border: "none", padding: "0px"}}
							actionsContainerStyles={{backgroundColor: "#FFFFFF", padding: "0px"}}
							actionCardStyles={{borderRadius: "8px"}}
							actions={[
								{
									actionIcon: <Home03Icon color={"primary"} width={24} height={24}/>,
									actionTitle: "Report a move",
									disableTranslation: false,
									actionClicked: () => setOpenReportMoveDialog(!openReportMoveDialog)
								},
								{
									actionIcon: <Pencil01Icon color={"primary"} width={24} height={24}/>,
									actionTitle: "Report a change",
									disableTranslation: false,
									actionClicked: () => setOpenReportChangeDialog(!openReportChangeDialog)
								}
							]}/>
					</Stack>
				)}
				
				<Stack gap={4} direction={"row"} alignItems={"flex-start"} style={{...fullWidth}}>
					<Stack gap={3} style={{...fullWidth}}>
						<SbPolicyHolderCard {...getInfoProps()} />
						{(subRelations && subRelations.length > 0) && (
							<SbPartyAccordion parties={subRelations} heading={
								<Stack direction={"row"} gap={2}>
									<SbTypography variant={"cardButtonHeader3"}>Family</SbTypography>
								</Stack>
							}/>
						)}
					</Stack>
					
					{!inMobileView && (
						<Stack gap={2}>
							<SbActionsCard headerIcon={<PlusCircleIcon width={20} height={20} color={"primary"}/>}
								headerTitle={"Actions"}
								containerStyle={{minWidth: "340px"}}
								actions={[
									{
										actionTitle: "Report a move",
										actionTitleTypography: {whiteSpace: "pre-line"},
										actionIcon: <Home03Icon color={"primary"} width={24} height={24}/>,
										disableTranslation: false,
										actionClicked: () => setOpenReportMoveDialog(!openReportMoveDialog)
									},
									{
										actionTitle: "Report a change",
										actionTitleTypography: {whiteSpace: "pre-line"},
										actionIcon: <Pencil01Icon color={"primary"} width={24} height={24}/>,
										disableTranslation: false,
										actionClicked: () => setOpenReportChangeDialog(!openReportChangeDialog)
									}
								]}
							/>
						</Stack>
					)}
				</Stack>
				
				<CustomerReportMoveDialog
					open={openReportMoveDialog}
					onClose={() => setOpenReportMoveDialog(!openReportMoveDialog)}
					showAlert={setShowFormSentAlert}
					customer={customer}
					contracts={contracts}
				/>
				
				<CustomerReportChangeDialog
					open={openReportChangeDialog}
					onClose={() => setOpenReportChangeDialog(!openReportChangeDialog)}
					showAlert={setShowFormSentAlert}
					customer={customer}
					contracts={contracts}
				/>
			</ErrorBoundary>
		</Stack>
	
	</TranslationProvider>;
}, [permissions.customerRead]);
