import React, {ReactElement, useEffect, useState} from "react";
import {
	ArrowNarrowLeftIcon,
	authorizedByLogin,
	PageInfo, SbIconButton,
	SbPageHeader, SbTypography,
	sharedGraphQlServices,
	TranslationProvider, useMobileView,
	userSessionProvider
} from "@surebase/shared-component-library";
import {Box, Card, CircularProgress, Stack, useTheme} from "@mui/material";
import {useSubscription} from "@apollo/client";
import {Instant} from "@js-joda/core";
import {MessageBox} from "../../services/message-box/graphql/MessageBox";
import {SUBSCRIBE_MESSAGES} from "../../services/message-box/graphql/MessageBoxQueries";
import {MessageList} from "./components/MessageList";
import {MessageView} from "./components/MessageView";
import {MessageBoxDataRepository} from "../../services/message-box/graphql/MessageBoxDataRepository";
import {Settings} from "../../global/Settings";

export const MessageBoxPage = authorizedByLogin(function (): ReactElement {
	const inMobileView = useMobileView();
	const [loading, setLoading] = useState<boolean>(true);
	const [selectedMessage, setSelectedMessage] = useState<MessageBox.Message>();
	const [messages, setMessages] = useState<MessageBox.Message[] | null>();
	const [messagesPageInfo, setMessagesPageInfo] = useState<PageInfo>();
	
	useEffect(() => {
		MessageBoxDataRepository.getMessagesForUser().then((messagesResponse: {
			messages: MessageBox.Message[];
			pageInfo?: MessageBox.PageInfo;
		}) => {
			setMessages(messagesResponse.messages);
			setMessagesPageInfo(messagesResponse.pageInfo);
			setLoading(false);
		});
		
	}, []);
	
	function changeSelectedMessage(message: MessageBox.Message) {
		setSelectedMessage(message);
	}
	
	useSubscription(SUBSCRIBE_MESSAGES, {
		client: sharedGraphQlServices.messageBoxService,
		onError: (error) => {
			console.log(error, "ws error");
		},
		variables: {
			username: userSessionProvider.userSession?.user.userName
		},
		fetchPolicy: "no-cache",
		shouldResubscribe: true,
		onData({data}) {
			const msgs = data.data.newMessageBoxChange.messages as MessageBox.Message[];
			if (msgs) {
				const newDataSet = msgs.concat(messages ?? []);
				setMessages(newDataSet);
			}
		}
	});
	
	async function removeMessage(messageIdRemoved: string) {
		MessageBoxDataRepository.deleteMessage(messageIdRemoved).then(() => {
			const newDataSet = Object.assign([], messages) as MessageBox.Message[];
			for (let i = newDataSet.length - 1; i >= 0; i--) {
				if (newDataSet[i].id === messageIdRemoved) {
					newDataSet.splice(i, 1);
					break;
				}
			}
			
			setMessages(newDataSet);
			setSelectedMessage(undefined);
		});
	}
	
	if (loading) {
		return <Box sx={{display: "flex", width: "100%", height: "100%"}}>
			<CircularProgress style={{margin: "auto"}}/>
		</Box>;
	}
	
	async function fetchMoreData() {
		if (!messagesPageInfo)
			return;
		
		MessageBoxDataRepository.getMessagesForUser(messagesPageInfo.endCursor).then((messagesResponse: {
			messages: MessageBox.Message[];
			pageInfo?: MessageBox.PageInfo;
		}) => {
			const newDataSet = Object.assign([], messages) as MessageBox.Message[];
			for (let i = 0; i < messagesResponse.messages.length; i++) {
				newDataSet.push(messagesResponse.messages[i]);
			}
			setMessages(newDataSet);
			setMessagesPageInfo(messagesResponse.pageInfo);
		});
	}
	
	async function markAsRead(message: MessageBox.Message) {
		if (message.readDateTime)
			return;
		
		MessageBoxDataRepository.updateMessageReadState(message.id).then((updateResult: boolean) => {
			const now = Instant.now().toString();
			const newMessages = messages?.map(it => {
				if (it.id === message.id)
					return {...it, readDateTime: now};
				else
					return it;
			}) ?? [];
			
			setMessages(newMessages);
		});
	}
	
	if (inMobileView && selectedMessage) {
		const theme = useTheme();
		return <TranslationProvider name={Settings.languageModule}>
			<Stack gap={2} style={{width: "100%", height: "100%"}}>
				<Stack direction={"row"} alignItems={"center"} gap={1}>
					<Stack direction={"row"} gap={2} style={{flex: "1 0 0"}}>
						<Card variant={"outlined"}>
							<SbIconButton IconButtonProps={{
								size: "small",
								style: {color: theme.palette.text.primary},
								onClick: () => {
									setSelectedMessage(undefined);
								}
							}}>
								<ArrowNarrowLeftIcon width={20} height={20}/>
							</SbIconButton>
						</Card>
						<SbTypography variant={"header2"}>Message box</SbTypography>
					</Stack>
				</Stack>
				<Stack direction={"row"} style={{flexGrow: "1", height: "100%", overflowY: "hidden"}} gap={4}>
					<Stack style={{height: "fit-content", width: "100%"}}>
						<MessageView message={selectedMessage} onRemoved={async (dat) => {
							await removeMessage(dat.id);
						}}/>
					</Stack>
				</Stack>
			</Stack>
		</TranslationProvider>;
	}
	
	return <TranslationProvider name={Settings.languageModule}>
		<Stack gap={2} style={{width: "100%", height: "100%"}}>
			<Stack>
				<SbPageHeader pageName={"Message box"} overviewRoute={"/customer-portal"} disableBreadcrumbs={true}/>
			</Stack>
			<Stack direction={"row"} style={{flexGrow: "1", height: "100%", overflowY: "hidden"}} gap={4}>
				<Stack style={{width: inMobileView ? "100%" : ""}}>
					<MessageList
						markAsRead={markAsRead}
						messages={messages || []}
						fetchMoreData={fetchMoreData}
						messagesPageInfo={messagesPageInfo}
						onClick={(message) => changeSelectedMessage(message)}
					/>
				</Stack>
				{(selectedMessage && !inMobileView) && (
					<Stack style={{height: "fit-content", width: "100%"}}>
						<MessageView message={selectedMessage} onRemoved={async (dat) => {
							await removeMessage(dat.id);
						}}/>
					</Stack>
				)}
			</Stack>
		</Stack>
	</TranslationProvider>;
});
