import {graphQlServices} from "../GraphQlServices";
import {Products} from "./Products";
import {GET_PRODUCTS, GET_SUPPLIERS} from "./ProductsQueries";

export class ProductsDataRepository {
	static async getSuppliers(krnList: string[]): Promise<Products.ProductSupplier[]> {
		if (krnList.length === 0)
			return [];
		
		const response = await graphQlServices.productServices.query<Products.Queries>(
			{
				query: GET_SUPPLIERS,
				variables: {
					where: {
						krn: {
							in: krnList
						}
					}
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.productSuppliers?.nodes ?? [];
	}
	
	static async getProducts(krnList: string[]): Promise<Products.Product[]> {
		if (krnList.length === 0)
			return [];
		
		const response = await graphQlServices.productServices.query<Products.Queries>(
			{
				query: GET_PRODUCTS,
				variables: {
					clientName: "overstappen",
					where: {
						and: [
							{state: {eq: "ACTIVE"}},
							{krn: {in: krnList}},
						]
					}
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.productsForClientName?.nodes ?? [];
	}
}
