import {gql} from "@apollo/client";

export const GET_SUPPLIERS = gql`
	query GetSuppliers($where:ProductSupplierFilterInput!) {
		productSuppliers(where:$where) {
			nodes {
				krn
				name
				nameLogoUrl
			}
		}
	}`;

export const GET_PRODUCTS = gql`
	query GetProductsForClientName($clientName: String!, $where:ProductFilterInput!){
		productsForClientName(clientName: $clientName, where: $where) {
			nodes {
				id
				krn
				name
				termsAndConditionsUrl
				insuranceCardUrl
				coverages {
					category
					coverageDays
					definition
					eventDescription
				}
				productIncentives {
					active
					productIncentiveDetailsKrn
				}
			}
		}
	}`;
