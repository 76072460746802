import React, {ReactNode, useEffect, useState} from "react";
import {
	formatter,
	Home02Icon,
	SbIconButton,
	SbMainCustomer,
	SbPage, SbUserSession,
	useMobileView,
	userSessionProvider,
	XCloseIcon,
} from "@surebase/shared-component-library";
import {Route, useLocation, useNavigate} from "react-router-dom";
import {Settings} from "../../global/Settings";
import {CustomerDetailsPage} from "../../pages/customer/CustomerDetailsPage";
import {ClaimsPage} from "../../pages/claims/ClaimsPage";
import {DashboardPage} from "../../pages/dashboard/DashboardPage";
import {InvitePage} from "../../pages/customer/InvitePage";
import {Box, Popover, useTheme} from "@mui/material";
import {ContractsPage} from "../../pages/contracts/ContractsPage";
import {InvoicesPage} from "../../pages/invoices/InvoicesPage";
import {ContactCard, ContactCardProps} from "../../pages/components/ContactCard";
import {PortalDataRepository} from "../../services/portal/graphql/PortalDataRepository";
import {Portal} from "../../services/portal/graphql/Portal";
import {SettingsPage} from "../../pages/settings/SettingsPage";
import {TenantHelper} from "../../helpers/TenantHelper";
import {MessageBoxPage} from "../../pages/messageBox/MessageBox";
import {Environment} from "../../global/Environment";
import {CookieBotHelper} from "../../helpers/CookieBotHelper";
import {HomePage} from "../../pages/home/HomePage";

export function Main() {
	const theme = useTheme();
	const inMobileView = useMobileView();
	const [customContent, setCustomContent] = useState<ReactNode | null>(() => {
		return (TenantHelper.isOverstappenTenant() && !userSessionProvider.userSession && window.location.pathname !== "/login" && window.location.pathname !== "/invite")
			? <HomePage onLoginClick={() => {
				window.location.pathname = "/login";
				setCustomContent(null);
			}}/> : null;
	});
	
	useEffect(() => {
		TenantHelper.setTitleAndIcon();
		if (TenantHelper.isOverstappenTenant()) {
			CookieBotHelper.AddCookieBotWithStyles();
			if (Environment.overstappenTagId) {
				const script = document.createElement("script");
				script.src = `https://www.googletagmanager.com/gtag/js?id=${Environment.overstappenTagId}`;
				script.async = true;
				document.head.appendChild(script);
				
				const scriptContent = document.createElement("script");
				scriptContent.innerHTML = `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${Environment.overstappenTagId}');
				`;
				document.head.appendChild(scriptContent);
				return () => {
					document.head.removeChild(script);
					document.head.removeChild(scriptContent);
				};
			}
		}
	}, []);
	
	
	const RedirectUrl = () => {
		const location = useLocation(); // Get the current location object
		const navigate = useNavigate();
		
		React.useEffect(() => {
			const navigateToModule = (path: string, moduleName: string, idIndex: number, queryParameters?: string) => {
				const parts = path.split("/");
				if (parts.length > idIndex && parts[1] === moduleName) {
					const entityId = parts[idIndex];
					const queryParams = queryParameters ? queryParameters.replace("?", "&") : "";
					navigate(`customer-portal/${moduleName}?id=${entityId}${queryParams}`, {replace: true});
				} else {
					navigate(`customer-portal/${moduleName}`, {replace: true});
				}
			};
			
			const path = location.pathname;
			if (path.startsWith("/claims/"))
				navigateToModule(path, "claims", 3);
			else if (path.startsWith("/contracts/"))
				navigateToModule(path, "contracts", 3, location.search);
			else if (path.startsWith("/customers/"))
				navigateToModule(path, "customer", 2);
			else
				navigate("/customer-portal", {replace: true});
			
		}, [location, navigate]);
		
		return null;
	};
	
	return <SbMainCustomer
		inMobileView={inMobileView}
		HeaderProps={{
			search: TenantHelper.isOverstappenTenant() ? <></> : undefined,
			homeIcon: (
				<Box className={"policy-card-container"}>
					<Home02Icon height={24} width={24} color={theme.palette.text.primary}/>
				</Box>
			),
			customLogo: TenantLogoContent({userSession: userSessionProvider.userSession}),
			UserWidgetProps: {
				containerStyle: {
					position: "relative",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end"
				},
				disableMessages: TenantHelper.isOverstappenTenant(),
				messagesLink: "/customer-portal/messages",
				disableWelcomeText: true,
				settingsLink: "/customer-portal/settings"
			},
			HayStackSearchProps: {
				searchAllAtStart: false,
				isCustomerPortal: true,
				style: {
					borderRadius: "8px",
					maxWidth: "unset"
				}
			},
			style: {
				padding: (TenantHelper.isSurebaseTenant() && inMobileView) ? "16px 0px 16px 16px" : "16px 24px"
			}
		}}
		routes={(
			<>
				<Route path={"customer-portal"}>
					<Route index element={<SbPage child={<DashboardPage/>}/>}/>
					<Route path={"settings"} element={<SbPage child={<SettingsPage/>}/>}/>
					<Route path={"claims"} element={<SbPage child={<ClaimsPage/>}/>}/>
					<Route path={"contracts"} element={<SbPage child={<ContractsPage/>}/>}/>
					<Route path={"customer"} element={<SbPage child={<CustomerDetailsPage/>}/>}/>
					<Route path={"invite/:id"} element={<SbPage child={<InvitePage/>}/>}/>
					<Route path={"invoices"} element={<SbPage child={<InvoicesPage/>}/>}/>
					{TenantHelper.isSurebaseTenant() ?
						<Route path={"messages"} element={<SbPage key={"messages"} child={<MessageBoxPage/>}/>}/>
						: undefined}
				</Route>
				<Route path={"*"} element={<RedirectUrl/>}/>
			</>
		)}
		customContent={Environment.disableHomePage ? null : customContent}
		translationModules={[
			"SureBase",
			Settings.languageModule
		]}
		menuItems={[]}
	/>;
}

function TenantLogoContent(props: { userSession: SbUserSession | null }) {
	const inMobileView = useMobileView();
	const [popupOpen, setPopupOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [contactCardDetails, setContactCardDetails] = useState<ContactCardProps>();
	
	useEffect(() => {
		if (props.userSession && TenantHelper.isOverstappenTenant()) {
			PortalDataRepository.getOverstappenDetails().then((result: Portal.BrokerPayload | null) => {
				if (!result)
					return;
				
				setContactCardDetails({
					heading: "Contact details",
					name: result.name,
					nameLink: "https://www.overstappen.nl",
					address: formatter.address({
						city: result.city,
						street: result.street,
						postalCode: result.postalCode,
						houseNumber: result.houseNumber,
						houseNumberAddition: result.houseNumberAddition
					}),
					contactNumber: formatter.contactNumber(result.contactNumber),
					emailAddress: result.email
				});
			});
		}
	}, [props.userSession]);
	
	
	const onLogoClick = (newState: boolean, event: any) => {
		event.stopPropagation();
		if (popupOpen === newState)
			return;
		
		setAnchorEl(newState ? event.currentTarget : null);
		setPopupOpen(newState);
	};
	
	if (TenantHelper.isOverstappenTenant()) {
		return <div key={"custom-logo-key"} style={{marginLeft: inMobileView ? "unset" : "auto"}}>
			<img src={"/overstappen-logo.png"} alt={"Overstappen"}
				style={{
					width: "127px",
					height: "18px",
					objectFit: "contain",
					display: "block",
					overflow: "hidden",
				}}
				onClick={(event) => onLogoClick(!popupOpen, event)}/>
			{(!inMobileView && contactCardDetails) && (
				<Popover
					anchorEl={anchorEl}
					open={popupOpen}
					onClose={(event) => onLogoClick(false, event)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					slotProps={{
						paper: {
							style: {
								width: "280px"
							}
						}
					}}
				>
					<ContactCard {...contactCardDetails}/>
				</Popover>
			)}
			{(inMobileView && contactCardDetails) && (
				<div className={`card-container ${popupOpen ? "visible" : ""}`}>
					<ContactCard {...contactCardDetails}
						actionSlot={(
							<SbIconButton IconButtonProps={{
								style: {marginLeft: "auto", padding: "0px"},
								onClick: (event) => onLogoClick(!popupOpen, event)
							}}>
								<XCloseIcon height={24} width={24}/>
							</SbIconButton>
						)}/>
				</div>
			)}
		</div>;
	}
	
	return <div key={"custom-logo-key"} style={{marginLeft: inMobileView ? "auto" : undefined}}>
		<img src={"/risk-direct.png"} alt={"Risk Direct"}
			style={{
				width: "75.5px",
				position: "relative",
				height: "40px",
				objectFit: "cover"
			}}/>
	</div>;
}
