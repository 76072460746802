import {Divider, Stack} from "@mui/material";
import React, {ReactNode, useRef} from "react";
import {
	CheckIcon,
	CurrencyEuroCircleIcon,
	SbIconButton,
	SbLink,
	SbLinkButton,
	SbTypographyRaw,
	Star01Icon,
	User01Icon,
	Users01Icon
} from "@surebase/shared-component-library";

interface HomePageProps {
	onLoginClick(): void;
}

export const HomePage = ((props: HomePageProps) => {
	const itemRef = useRef<null | HTMLDivElement>(null);
	return <Stack style={{background: "#FFF"}}>
		<Stack ref={itemRef} style={{
			width: "100%",
			height: "80px",
			justifyContent: "center",
			alignItems: "center",
			flexShrink: 0
		}}>
			<Stack direction={"row"} alignItems={"center"} style={{
				width: "100%",
				height: "40px",
				padding: "0px 32px 0px 32px"
			}}>
				<Stack>
					<img src={"/overstappen-logo.png"} alt={"Overstappen"}
						style={{
							width: "200px",
							height: "21px",
						}}/>
				</Stack>
				<SbIconButton IconButtonProps={{
					size: "small",
					style: {
						border: "1px solid #D0D5DD",
						borderRadius: "8px",
						padding: "10px 16px",
						marginLeft: "auto"
					},
					onClick: props.onLoginClick
				}}>
					<Stack gap={1} direction={"row"}>
						<User01Icon height={20} width={20} color={"#475467"}/>
						<SbTypographyRaw style={{
							color: "#475467",
							fontSize: "14px",
							fontWeight: "700",
							lineHeight: "20px"
						}}>
							Login
						</SbTypographyRaw>
					</Stack>
				</SbIconButton>
			</Stack>
		</Stack>
		<Divider/>
		<Stack style={{
			position: "relative",
			background: "radial-gradient(78.61% 35.94% at 50% 50%, #0CBB8A 0%, #097D56 100%)",
			display: "flex",
			width: "100%",
			padding: "48px 0px 56px 0px",
			flexDirection: "column",
			alignItems: "center",
			gap: "64px",
			backgroundColor: "#018A62"
		}}>
			<Stack direction={"row"} style={{
				display: "flex",
				padding: "0px 32px",
				justifyContent: "center",
				alignItems: "flex-start",
				gap: "32px"
			}}>
				<Stack style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					gap: "24px",
					flex: "1 0 0"
				}}>
					<Stack style={{position: "absolute", bottom: "0px", left: "0px", zIndex: 5}}>
						<img src={"/home/home-bottom-left-piece.svg"} alt={""}/>
					</Stack>
					<Stack style={{zIndex: 6}}>
						<SbTypographyRaw variant={"custom"} style={{
							color: "#63F2C3",
							fontSize: "60px",
							fontWeight: "700",
							lineHeight: "72px",
							letterSpacing: "-1.2px"
						}}>
							MijnOverstappen
						</SbTypographyRaw>
					</Stack>
					<Stack gap={2} alignItems={"flex-start"} style={{zIndex: 6}}>
						<ProsCheckMark text={"Persoonlijke omgeving"}/>
						<ProsCheckMark text={"Altijd inzage in je gegevens"}/>
						<ProsCheckMark text={"Jouw zorgverzekering"}/>
					</Stack>
				</Stack>
			</Stack>
			
			<Stack style={{position: "absolute", top: "0px", right: "0px"}}>
				<img src={"/home/home-top-right-piece.svg"} alt={""}/>
			</Stack>
		</Stack>
		
		<Stack gap={"64px"} alignItems={"center"} style={{width: "100%", padding: "32px 0px"}}>
			<Stack gap={"32px"} alignItems={"flex-start"} style={{width: "100%", padding: "0px 32px"}}>
				<Stack direction={"row"} gap={"58px"} alignItems={"flex-start"} alignSelf={"stretch"}>
					<SellingPoint heading={"Account"} text={"Maak nu je persoonlijke account aan"}
						icon={(
							<Users01Icon height={24} width={24} color={"primary"}/>
						)}/>
					<Divider flexItem={true} orientation={"vertical"}/>
					<SellingPoint heading={"Besparen"} text={"Bespaar op verschillende producten"}
						icon={(
							<CurrencyEuroCircleIcon height={24} width={24} color={"primary"}/>
						)}/>
					<Divider flexItem={true} orientation={"vertical"}/>
					<SellingPoint heading={"Overstappen.nl"} text={"Klanten beoordelen Overstappen met een 9.2"}
						icon={(
							<Star01Icon height={24} width={24} color={"primary"}/>
						)}/>
				</Stack>
			</Stack>
		</Stack>
		<Stack id={"content-media"} style={{
			width: "100%",
			padding: "96px 0px",
			alignItems: "center",
			gap: "64px",
			background: "#F9FAFB"
		}}>
			<Stack id={"container"} style={{
				width: "100%",
				padding: "0px 32px",
				alignItems: "flex-start",
				gap: "32px"
			}}>
				<Stack id={"content"} direction={"row"} style={{
					alignItems: "flex-start",
					gap: "58px",
					alignSelf: "stretch"
				}}>
					<img src={"/home/about-our-team.svg"} alt={""}/>
					<Stack style={{width: "100%", alignItems: "flex-start", gap: "32px"}}>
						<Stack style={{width: "100%", alignItems: "flex-start", gap: "8px", alignSelf: "stretch"}}>
							<Stack style={{width: "100%", alignItems: "flex-start", gap: "4px", alignSelf: "stretch"}}>
								<SbTypographyRaw variant={"custom"} style={{
									color: "#0CBB8A",
									fontSize: "14px",
									fontWeight: "700",
									lineHeight: "20px",
									letterSpacing: "1.12px",
									textTransform: "uppercase"
								}}>
									Lorem ipsum
								</SbTypographyRaw>
								
								<SbTypographyRaw variant={"custom"} style={{
									color: "#101828",
									fontSize: "42px",
									fontWeight: "700",
									lineHeight: "50px",
									letterSpacing: "-0.84px",
								}}>
									Fames purus risus
								</SbTypographyRaw>
							</Stack>
							
							<SbTypographyRaw variant={"custom"} style={{
								color: "#667085",
								fontSize: "18px",
								fontWeight: "500",
								lineHeight: "28px",
							}}>
								Lorem ipsum dolor sit amet consectetur. Fames purus risus pharetra in tortor velit.
								Elementum adipiscing eu viverra feugiat eget. Mauris cursus amet tincidunt cursus at
								arcu ante. Sed fames et sed risus sodales felis quam aliquam.
							</SbTypographyRaw>
						
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
		
		<Stack style={{
			alignItems: "flex-start",
			alignSelf: "stretch"
		}}>
			
			<Stack style={{
				width: "100%",
				padding: "64px 0px",
				alignItems: "center",
				gap: "64px",
				alignSelf: "stretch",
			}}>
				<Stack style={{
					width: "100%",
					padding: "0px 32px",
					alignItems: "flex-start",
					gap: "48px",
				}}>
					<Stack direction={"row"} style={{
						alignItems: "flex-start",
						gap: "64px",
						alignSelf: "stretch",
						margin: "auto"
					}}>
						<Stack direction={"row"} style={{alignItems: "flex-start"}}>
							<img src={"/home/os-logomark.svg"} alt={""}/>
						</Stack>
						<Stack direction={"row"} style={{alignItems: "flex-start", gap: "32px"}}>
							<FooterLink heading={"Overstappen.nl"} links={[
								{name: "Over ons", url: "https://www.overstappen.nl/over-ons/"},
								{name: "Vacatures", url: "https://www.overstappen.nl/vacatures/"},
								{name: "Onze experts", url: "https://www.overstappen.nl/onze-experts/"}
							]}/>
							<FooterLink heading={"News"} links={[
								{name: "Nieuws", url: "https://www.overstappen.nl/nieuws/"},
								{name: "Pers", url: "https://www.overstappen.nl/pers/"},
							]}/>
							<FooterLink heading={"Information"} links={[
								{
									name: "Veelgestelde vragen",
									url: "https://www.overstappen.nl/klantenservice/veelgestelde-vragen/"
								},
								{name: "Klantenservice", url: "https://www.overstappen.nl/klantenservice/"},
								{name: "Partner worden", url: "https://www.overstappen.nl/partner-worden/"}
							]}/>
							<FooterLink heading={"Contact"} links={[
								{name: "020 - 765 10 99", url: "tel:0207604242", openHere: true},
								{name: "Whatsapp", url: "https://api.whatsapp.com/send?phone=3197014202321"},
								{name: "service@overstappen.nl", url: "mailto:service@overstappen.nl"}
							]}/>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			
			<Stack style={{
				padding: "32px 0px",
				alignItems: "center",
				gap: "64px",
				alignSelf: "stretch",
			}}>
				<Stack style={{
					padding: "0px 32px",
					alignItems: "flex-start",
					gap: "64px"
				}}>
					<Stack direction={"row"} style={{
						alignItems: "center",
						gap: "32px",
						alignSelf: "stretch",
					}}>
						<Stack>
							<SbTypographyRaw variant={"custom"} style={{
								color: " #98A2B3",
								fontSize: "12px",
								fontStyle: "normal",
								fontWeight: "500",
								lineHeight: "18px",
							}}>
								Copyright © 2009-2023 Overstappen.nl B.V. | Overtoom 62 1054 HL Amsterdam | KvK 34331885
								| BTW NL820572937B01 | AFM 12012535
							</SbTypographyRaw>
							
							<Stack direction={"row"} gap={"4px"}>
								<SbLinkButton to={"https://www.overstappen.nl/gebruikersvoorwaarden-mijnoverstappen"}
									windowOpenFeatures={{newTab: true}}
									ButtonProps={{
										style: {padding: "0px"},
										variant: "text",
									}}>
									<SbTypographyRaw variant={"custom"} style={{
										color: " #98A2B3",
										fontSize: "12px",
										fontStyle: "normal",
										fontWeight: "500",
										lineHeight: "18px",
									}}>Gebruikersvoorwaarden</SbTypographyRaw>
								</SbLinkButton>
								
								<SbTypographyRaw variant={"custom"} style={{
									color: " #98A2B3",
									fontSize: "12px",
									fontStyle: "normal",
									fontWeight: "500",
									lineHeight: "18px",
								}}>|</SbTypographyRaw>
								
								<SbLinkButton
									to={"https://www.overstappen.nl/privacy-cookies/privacyverklaring-overstappen-nl-b-v"}
									windowOpenFeatures={{newTab: true}}
									ButtonProps={{
										style: {padding: "0px"},
										variant: "text",
									}}>
									<SbTypographyRaw variant={"custom"} style={{
										color: " #98A2B3",
										fontSize: "12px",
										fontStyle: "normal",
										fontWeight: "500",
										lineHeight: "18px",
									}}>Privacyverklaring</SbTypographyRaw>
								</SbLinkButton>
							</Stack>
						</Stack>
						<SocialMediaIcons/>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	</Stack>;
});

function ProsCheckMark(props: { text: string }) {
	return <Stack direction={"row"} gap={1} alignItems={"center"}>
		<CheckIcon height={24} width={24} color={"#FFFFFF"}/>
		<SbTypographyRaw variant={"custom"} style={{
			color: "#FFFFFF",
			fontSize: "20px",
			fontWeight: "500",
			lineHeight: "28px",
		}}>
			{props.text}
		</SbTypographyRaw>
	</Stack>;
}

function SellingPoint(props: { heading: string; text: string; icon: ReactNode }) {
	return <Stack direction={"row"} gap={2} alignItems={"center"} style={{width: "100%", flex: "1 1 0"}}>
		{props.icon}
		<Stack gap={"2px"} style={{alignItems: "flex-start", flex: "1 1 0"}}>
			<SbTypographyRaw variant={"custom"} style={{
				color: "#101828",
				fontSize: "18px",
				fontWeight: "700",
				lineHeight: "28px"
			}}>
				{props.heading}
			</SbTypographyRaw>
			<SbTypographyRaw variant={"custom"} style={{
				color: "#667085",
				fontSize: "18px",
				fontWeight: "400",
				lineHeight: "28px"
			}}>
				{props.text}
			</SbTypographyRaw>
		</Stack>
	</Stack>;
}

interface FooterLinkProp {
	name: string;
	url: string;
	openHere?: boolean;
}

function FooterLink(props: { heading: string; links: FooterLinkProp[] }) {
	return <Stack id={"footer-links-col"} style={{width: "100%", alignItems: "flex-start", gap: "16px",}}>
		<SbTypographyRaw variant={"custom"} style={{
			color: "#101828",
			fontSize: "16px",
			fontWeight: "700",
			lineHeight: "24px"
		}}>
			{props.heading}
		</SbTypographyRaw>
		
		<Stack id={"logo-text"} gap={"12px"} style={{width: "100%", alignItems: "flex-start"}}>
			{props.links.map((link, index) => (
				<Stack key={index}>
					<SbLink to={link.url} windowOpenFeatures={{newTab: (link.openHere !== true)}}
						LinkProps={{style: {textDecoration: "none"}}}>
						<SbTypographyRaw variant={"custom"} style={{
							color: "#667085",
							fontSize: "16px",
							fontWeight: "500",
							lineHeight: "24px"
						}}>
							{link.name}
						</SbTypographyRaw>
					</SbLink>
				</Stack>
			))}
		</Stack>
	</Stack>;
}

function SocialMediaIcons() {
	return <Stack id={"frame"} style={{alignItems: "center", gap: "32px"}}>
		<Stack id={"social-icons"} direction={"row"} style={{alignItems: "center", gap: "16px"}}>
			<SbLink to={"https://www.linkedin.com/company/overstappen-nl/"} windowOpenFeatures={{newTab: true}}>
				<img src={"/home/linkedin.svg"} alt={""}/>
			</SbLink>
			<SbLink to={"https://www.facebook.com/overstappen/"} windowOpenFeatures={{newTab: true}}>
				<img src={"/home/facebook.svg"} alt={""}/>
			</SbLink>
			<SbLink to={"https://twitter.com/overstappen"} windowOpenFeatures={{newTab: true}}>
				<img src={"/home/x.svg"} alt={""}/>
			</SbLink>
			<SbLink to={"https://www.tiktok.com/@overstappen"} windowOpenFeatures={{newTab: true}}>
				<img src={"/home/tiktok.svg"} alt={""}/>
			</SbLink>
			<SbLink to={"https://www.youtube.com/@overstappennl"} windowOpenFeatures={{newTab: true}}>
				<img src={"/home/youtube.svg"} alt={""}/>
			</SbLink>
		</Stack>
	</Stack>;
}
