import {gql} from "@apollo/client";

export const ACCEPT_INVITE = gql`
	mutation AcceptInvite($input: AcceptInviteInput!) {
		acceptInvite(input: $input) {
			inviteAcceptedResponse {
				successful
			}
		}
	}`;

export const GET_AVAILABLE_PRODUCTS = gql`
	query GetProducts {
		availableProducts {
			id
			name
			shortName
			icon
			link
		}
	}`;

export const GET_OVERSTAPPEN_DETAILS = gql`
	query GetOverstappenDetails {
		overstappenDetails {
			krn
			brokerId
			name
			street
			houseNumber
			houseNumberAddition
			city
			postalCode
			contactNumber
			email
		}
	}`;

export const GET_OVERSTAPPEN_PRODUCTS = gql`
	query GetOverstappenProducts {
		overstappenProducts {
			id
			name
			shortName
			icon
			link
		}
	}`;

export const GET_CUSTOMER_REGISTRY_DETAILS = gql`
	query GetCustomerRegisterDetails($request: CustomerRegisterDetailRequestInput!) {
		customerRegisterDetails(request: $request) {
			customerRegistryId
			birthDate
			mobile
			email
			firstName
			surname
			postalCode
			houseNumber
			houseNumberAddition
			street
			city
		}
	}`;

export const GET_TOP_DASHBOARD_ORDERS = gql`
	query GetOrders($customerRegistryId: UUID!, $first: Int, $where: OrderFilterInput, $order: [OrderSortInput!]) {
		orders(customerRegistryId: $customerRegistryId, first: $first, where: $where, order: $order) {
			pageInfo {
				hasNextPage
				endCursor
			}
			nodes {
				id
				orderDate
				productCategory
				orderStatus
				underwritingResult
				userDesiresIncentive
				package
				{
					policyDetails {
						refKey
						contractNumber
						productName
						externalSourceName
						productDescription
						underwritingResult
					}
					expiryDate
					customProperties
					{
						supplierKrn
						productKrn
						endDateText
					}
				}
			}
		}
	}`;

export const GET_ORDERS = gql`
	query GetOrders($customerRegistryId: UUID!, $where: OrderFilterInput, $order: [OrderSortInput!]) {
		orders(customerRegistryId: $customerRegistryId, where: $where, order: $order) {
			pageInfo {
				hasNextPage
				endCursor
			}
			nodes {
				id
				orderDate
				productCategory
				orderStatus
				underwritingResult
				policyId
				userDesiresIncentive
				paymentDetails {
					collectionAccountIban
					paymentMethod
					paymentTermInMonths
				}
				package
				{
					productDescription
					offerNumber
					effectiveDate
					expiryDate
					totalInstallmentAmount
					deductibleAmount
					paymentTermInMonths
					policyDetails
					{
						refKey
						contractNumber
						productName
						externalSourceName
						productDescription
						companyNameDescription
						totalInstallmentAmount
						paymentTermInMonths
						underwritingResult
						customProperties
						{
							productKrn
						}
						partyPolicyHolders
						{
							prefixes
							initials
							callName
							surname
							mobile
							email
							birthdate
							address
							{
								city
								houseNumber
								houseNumberAddition
								houseLetter
								postalCode
								street
							}
							
						}
						partyPartners
						{
							prefixes
							initials
							callName
							surname
							mobile
							email
							birthdate
						}
						partyChildren
						{
							prefixes
							initials
							callName
							surname
							mobile
							email
							birthdate
						}
						coverageBasicHealths
						{
							deductibleAmount
							deductibleDiscountAmount
						}
						coverageAdditionalHealths
						{
							deductibleAmount
							deductibleDiscountAmount
						}
						coverageDentistHealths
						{
							deductibleAmount
							deductibleDiscountAmount
						}
					}
					customProperties
					{
						supplierKrn
						productKrn
						endDateText
					}
					documents {
						fileName
						attachmentType
						documentUrl
						fileExtension
					}
				}
			}
		}
	}`;
