import {KiziStage, UrlTenantFinder} from "@surebase/shared-component-library";

export let Environment: {
	frontendHost: string;
	backendHost: string;
	cognitoClientId: string;
	kiziStage: KiziStage;
	debug?: boolean;
	kiziSubscriptionUrl: string;
	cognitoDomainPrefix: string;
	overstappenTagId?: string;
	cookieBotId?: string;
	assetsUrl: string;
	incentivesEnabled: boolean;
	disableHomePage?: boolean;
};

const tenant = UrlTenantFinder.findTenant(window.location.host) ?? "surebase";
const stage = process.env.REACT_APP_STAGE?.toLowerCase();
const cognitoPrefix = tenant === "overstappen" ? "surebase-customerportal-accounts-overstappen" : "surebase-customerportal-accounts";
switch (stage) {
	case "kizilocal":
		Environment = {
			frontendHost: "http://localhost:3000",
			backendHost: "https://api-dev.surebase.nl",
			cognitoClientId: tenant === "overstappen" ? "92792a3d17h0cfj1e3uqhqtpp" : "7239dt0krj3uhct0gm5s59h909",
			kiziStage: "kizidev",
			debug: true,
			kiziSubscriptionUrl: "wss://afkqh8qj4e.execute-api.eu-west-1.amazonaws.com/kizidev",
			cognitoDomainPrefix: cognitoPrefix,
			assetsUrl: "https://assets.dev.surebase.nl/logos/{type}/{id}/logo.png",
			incentivesEnabled: true,
		};
		break;
	case "kizidev":
		Environment = {
			frontendHost: `https://dev.mijn.${tenant}.nl`,
			backendHost: "https://api-dev.surebase.nl",
			cognitoClientId: tenant === "overstappen" ? "92792a3d17h0cfj1e3uqhqtpp" : "7239dt0krj3uhct0gm5s59h909",
			kiziStage: "kizidev",
			kiziSubscriptionUrl: "wss://afkqh8qj4e.execute-api.eu-west-1.amazonaws.com/kizidev",
			cognitoDomainPrefix: cognitoPrefix,
			assetsUrl: "https://assets.dev.surebase.nl/logos/{type}/{id}/logo.png",
			incentivesEnabled: true
		};
		break;
	case "kizitest":
		Environment = {
			frontendHost: `https://test.mijn.${tenant}.nl`,
			backendHost: "https://api-test.surebase.nl",
			cognitoClientId: "",
			kiziStage: "kizitest",
			kiziSubscriptionUrl: "",
			cognitoDomainPrefix: cognitoPrefix,
			assetsUrl: "https://assets.dev.surebase.nl/logos/{type}/{id}/logo.png",
			incentivesEnabled: false
		};
		break;
	case "kiziacc":
		Environment = {
			frontendHost: `https://acc.mijn.${tenant}.nl`,
			backendHost: "https://api-acc.surebase.nl",
			cognitoClientId: tenant === "overstappen" ? "7goes3p78ackila0ofmkj1pk2a" : "4ec0abir8ac3rt011chmpncb0a",
			kiziStage: "kiziacc",
			kiziSubscriptionUrl: "wss://x5e5qna9c9.execute-api.eu-west-1.amazonaws.com/kiziacc",
			cognitoDomainPrefix: cognitoPrefix,
			overstappenTagId: "G-6VS4S8C0EY",
			assetsUrl: "https://assets.acc.surebase.nl/logos/{type}/{id}/logo.png",
			incentivesEnabled: true
		};
		break;
	case "kiziprod":
		Environment = {
			frontendHost: `https://mijn.${tenant}.nl`,
			backendHost: "https://api.surebase.nl",
			cognitoClientId: tenant === "overstappen" ? "ga6vuami40srmhk66pmr2a9ek" : "7gniqhtekgqfki9rvbgjd64q7l",
			kiziStage: "kiziprod",
			kiziSubscriptionUrl: "wss://qbk1581n3g.execute-api.eu-west-1.amazonaws.com/kiziprod",
			cognitoDomainPrefix: cognitoPrefix,
			overstappenTagId: "G-Z5ZHY68EEB",
			cookieBotId: tenant === "overstappen" ? "cb1325ae-dce2-48bd-b89d-6906f97644f1" : undefined,
			assetsUrl: "https://assets.surebase.nl/logos/{type}/{id}/logo.png",
			incentivesEnabled: true,
			disableHomePage: true
		};
		break;
	default:
		throw new Error("Cannot identify runtime stage.");
}
