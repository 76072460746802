import {
	authorizedByPermission, DossierDownload,
	fullWidth, Loading02Icon, SbButton, SbClaimCard, SbLink,
	SbPageHeader,
	SbTypography,
	TranslationProvider,
	useMobileView
} from "@surebase/shared-component-library";
import {permissions} from "../../global/Permissions";
import React, {useEffect, useState} from "react";
import {Settings} from "../../global/Settings";
import {Box, Card, CardActions, CardHeader, CircularProgress, Divider, Stack, useTheme} from "@mui/material";
import {ClaimsDataRepository} from "../../services/claims/graphql/ClaimsDataRepository";
import {Claims} from "../../services/claims/graphql/Claims";
import {useNavigate} from "react-router-dom";
import {ContractsDataRepository} from "../../services/contracts/graphql/ContractsDataRepository";
import {grey} from "@mui/material/colors";
import {DossierDataRepository} from "../../services/dossiers/graphql/DossierDataRepository";
import {DossierHelper} from "../../helpers/DossierHelper";
import {Contracts} from "../../services/contracts/graphql/Contracts";
import {NotFoundCard} from "../components/NotFoundCard";

export const ClaimsPage = authorizedByPermission(() => {
	const inMobileView = useMobileView();
	const navigate = useNavigate();
	const theme = useTheme();
	const [loading, setLoading] = useState<boolean>(false);
	const [fetchPageData, setFetchPageData] = useState<boolean>(true);
	const [claims, setClaims] = useState<Claims.Claim[]>([]);
	const [expandedClaim, setExpandedClaim] = useState<string | null>(null);
	const [coverageTypes, setCoverageTypes] = useState<Record<string, string>>({});
	
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		setExpandedClaim(urlParams.get("id"));
		
	}, [navigate]);
	
	if (fetchPageData) {
		setLoading(true);
		setFetchPageData(false);
		
		ContractsDataRepository.getContractsCoverageTypes().then((coverageTypesResult: Contracts.CoverageTypeItem[]) => {
			const record: Record<string, string> = Object.fromEntries(
				coverageTypesResult.map(x => [x.code, x.description])
			);
			
			setCoverageTypes(record);
		});
		
		ClaimsDataRepository.getClaims().then((claimsResult: Claims.Claim[]) => {
			setLoading(false);
			setClaims(claimsResult);
		});
	}
	
	if (loading)
		return <Box sx={{display: "flex", width: "100%", height: "100%"}}>
			<CircularProgress style={{margin: "auto"}}/>
		</Box>;
	
	const navigateToLogClaimUrl = () => {
		window.open(Settings.newSchadeUrl);
	};
	
	const logClaimButton = () => {
		return <SbButton ButtonProps={{
			size: "large",
			variant: "contained",
			color: "primary",
			style: {padding: "10px 18px", borderRadius: "4px"},
			onClick: navigateToLogClaimUrl
		}}>
			<SbTypography variant={"buttonLarge"}>Report a claim</SbTypography>
		</SbButton>;
	};
	
	const getDocumentsForClaim = async (claim: Claims.Claim): Promise<DossierDownload[]> => {
		if (!claim.policyDetails.refKey)
			return [];
		
		const documents = await DossierDataRepository.getClaimDocuments(claim.policyDetails.refKey);
		return DossierHelper.buildDossierDownloads(documents);
	};
	
	const getContractCoverage = async (contractId: string): Promise<Contracts.Coverage[] | null> => {
		return ContractsDataRepository.getContractCoverage(contractId).then((coverageArray: Contracts.Coverage[] | null) => {
			if (!coverageArray)
				return coverageArray;
			
			const length = coverageArray.length;
			for (let i = 0; i < length; i++) {
				const item: any = coverageArray[i];
				if (!item.productDescription)
					continue;
				
				const value = coverageTypes[item.productDescription];
				if (value) {
					item.productDescription = value;
				}
			}
			
			return coverageArray;
		});
	};
	
	return <TranslationProvider name={Settings.languageModule}>
		<Stack gap={2} style={{...fullWidth}}>
			<Stack>
				<SbPageHeader pageName={"Claims"} overviewRoute={"/customer-portal"} disableBreadcrumbs={true}/>
			</Stack>
			
			<Stack gap={inMobileView ? 2 : 4} direction={inMobileView ? "column" : "row"} style={{...fullWidth}}>
				{inMobileView && (
					logClaimButton()
				)}
				
				{claims.length === 0 && (
					<Stack gap={3} style={{...fullWidth}}>
						<NotFoundCard heading={"No claim(s) found"} link={(
							<SbLink to={Settings.newSchadeUrl} windowOpenFeatures={{newTab: true}} LinkProps={{
								style: {
									color: theme.palette.text.secondary,
									textDecorationColor: theme.palette.text.secondary
								}
							}}>
								<SbTypography>Report a claim here or via the button</SbTypography>
							</SbLink>)}/>
					</Stack>
				)}
				
				{claims.length !== 0 && (
					<Stack gap={3} style={{...fullWidth}}>
						{claims.map((claim, index) => (
							<SbClaimCard key={index}
								claim={claim}
								getDocumentsFunction={() => getDocumentsForClaim(claim)}
								getCoverageFunction={getContractCoverage}
								defaultExpanded={(expandedClaim !== null && expandedClaim === claim.id)}
							/>
						))}
					</Stack>
				)}
				
				{!inMobileView && (
					<Stack gap={2} style={{minWidth: "340px"}}>
						<Card variant={"outlined"}>
							<CardHeader style={{backgroundColor: "#FFFFFF", padding: "16px", gap: "24px"}}
								avatar={(
									<Stack gap={2} alignItems={"center"} direction={"row"}>
										<Loading02Icon color={"primary"} width={24} height={24}/>
										<SbTypography variant={"cardButtonTitle1"}>Report a claim</SbTypography>
									</Stack>
								)}>
							</CardHeader>
							<Divider color={grey[300]}/>
							<CardActions style={{padding: "16px", gap: "16px"}}>
								<Stack gap={2} style={{width: "100%"}}>
									<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
										Click here to report a new claim
									</SbTypography>
									{logClaimButton()}
								</Stack>
							</CardActions>
						</Card>
					</Stack>
				)}
			</Stack>
		</Stack>
	</TranslationProvider>;
}, [permissions.claimsRead]);
